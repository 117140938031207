// https://fonts.googleapis.com/css2?family=Ubuntu+Mono
// v15
@font-face {
    font-family: 'Ubuntu Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Ubuntu Mono'), local('UbuntuMono'), local('Ubuntu Mono Regular'),
         url('../fonts/ubuntu-mono-latin-400.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Ubuntu Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/ubuntu-mono-latin-ext-400.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: 'Ubuntu Mono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/ubuntu-mono-cyryllic-400.woff2') format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

// https://fonts.googleapis.com/css2?family=Rubik
// v14
// Rubik:ital,wght@0,400;0,500;1,400;1,500
$rubik_font_path: '../fonts/rubik';

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-cyrillic-400.woff2) format('woff2'),
         url(#{$rubik_font_path}/rubik-400.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-cyrillic-400-italic.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-cyrillic-ext-400.woff2) format('woff2'),
         url(#{$rubik_font_path}/rubik-400.woff) format('woff');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-cyrillic-ext-400-italic.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-latin-400.woff2) format('woff2'),
         url(#{$rubik_font_path}/rubik-400.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-latin-400-italic.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-latin-ext-400.woff2) format('woff2')
         url(#{$rubik_font_path}/rubik-400.woff) format('woff');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-latin-ext-400-italic.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-cyrillic-500.woff2) format('woff2'),
         url(#{$rubik_font_path}/rubik-500.woff) format('woff');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-cyrillic-500-italic.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-cyrillic-ext-500.woff2) format('woff2'),
         url(#{$rubik_font_path}/rubik-500.woff) format('woff');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-cyrillic-ext-500-italic.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-latin-500.woff2) format('woff2'),
         url(#{$rubik_font_path}/rubik-500.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-latin-500-italic.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-latin-ext-500.woff2) format('woff2'),
         url(#{$rubik_font_path}/rubik-500.woff) format('woff');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(#{$rubik_font_path}/rubik-latin-ext-500-italic.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
